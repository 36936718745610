import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from '../../services/authentication.service';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { WorkOrderService } from '../../services/work-order.service';
import { interval, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  loggedUser: any;
  notificationsList = [];
  notifications = 0;
  notificationsSeen = [];
  searchVisible: boolean = false;
  quickViewVisible: boolean = false;
  isFolded: boolean;
  isExpand: boolean;
  bellNotificationSubscription !: Subscription;
  language:string;

  constructor(
    private themeService: ThemeConstantService,
    private authService: AuthenticationService,
    private router: Router,
    private workOrderService: WorkOrderService,

  ) { }

  async ngOnInit(): Promise<void> {
    this.language = localStorage.getItem('language') || 'en';
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    const loggedUser = localStorage.getItem('currentUser');
    this.loggedUser =  loggedUser? JSON.parse(localStorage.getItem('currentUser')) : null;
    this.bellNotificationSubscription = timer(0,10000).pipe(
      switchMap(()=> this.workOrderService.getNotificationCount())
    ).subscribe(res => {
      if(res.data.count > 0){
        this.notifications = res.data.count
      }
    });
  }

  ngOnDestroy(): void {
    this.bellNotificationSubscription.unsubscribe();
  }

  openBellNotifications(){
    this.workOrderService.getNotificationsByCount(3).subscribe(res => {
      this.notificationsList = res.data;
      res.data.forEach((x)=>{
        x.createdAt = moment(x.createdAt).locale(this.language).fromNow()
      })
    })
    this.workOrderService.seenNotification().subscribe();
    this.notifications = 0;
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }

  toProfile(){
    this.router.navigate(['/profile']);
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  changeLanguage(language:string){
    localStorage.setItem('language',language);
    window.location.reload();
  }
}
function reflesh() {
  throw new Error('Function not implemented.');
}

