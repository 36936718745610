import {EventEmitter, Injectable} from '@angular/core';
import {HubConnection, HubConnectionBuilder, LogLevel} from '@aspnet/signalr';
import {ChatMessage} from './response/chat-message';
import {environment} from '../../../environments/environment';
import {ChatRoom} from './response/chat-room';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  messageReceived = new EventEmitter<ChatMessage>();

  connectionEstablished = new EventEmitter<boolean>();

  public chatRooms: Array<ChatRoom>;

  public newMessage = false;

  public canSeeMessage = false;

  public currentRoomId: number;

  public roomsReadySub = new Subject<boolean>();

  public roomsReady = false;

  private connectionIsEstablished = false;

  private hubConnection: HubConnection;


  constructor() {
  }

  public createConnection() {
    if (this.hubConnection == null) {
      this.hubConnection = new HubConnectionBuilder().withUrl(environment.websocketUrl + '/chatHub', {
        accessTokenFactory: () => localStorage.getItem('accessToken')
      })
        .configureLogging(LogLevel.Information)
        .build();
      this.startConnection();
    }
  }

  public getRooms() {
    this.hubConnection.invoke('GetRooms').then((response: Array<ChatRoom>) => {
      this.chatRooms = response;
      this.chatRooms.forEach(c => {
        c.newMessage = false;
        this.getRoomMessages(c.id);
      });
      this.roomsReady = true;
      this.roomsReadySub.next(true);
    });
  }

  public getRoomMessages(workOrderId: number) {
    this.hubConnection.invoke('GetMessageHistory', workOrderId).then((response: Array<ChatMessage>) => {
      const chatRoom = this.chatRooms.find(c => c.id === workOrderId);
      if (chatRoom != null) {
        chatRoom.messages = response;
      }
    });
  }

  public sendMessage(workOrderId: number, message: string) {
    this.hubConnection.invoke('SendToRoom', workOrderId, message).then(response => {
      console.log(message, workOrderId);
    });
  }

  public async stopConnection() {
    await this.hubConnection.stop();
    this.hubConnection = null;
    this.chatRooms = [];
    this.newMessage = false;
    this.canSeeMessage = false;
    this.roomsReady = false;
    this.roomsReadySub.next(false);
  }

  private startConnection() {
    this.hubConnection.start().then(() => {
      this.connectionIsEstablished = true;
      this.connectionEstablished.emit(true);
      this.registerOnServerEvents();
    }).catch(err => {
      console.log('Error while establishing connection, retrying...');
      setTimeout(() => {
        this.startConnection();
      }, 5000);
    });
  }

  private registerOnServerEvents() {
    this.hubConnection.on('newMessage', (data: ChatMessage) => {
      const chatRoom = this.chatRooms.find(c => c.id === data.workOrderId);
      if (chatRoom != null) {
        chatRoom.messages.push(data);
        this.messageReceived.emit(data);
        if (!this.canSeeMessage) {
          this.newMessage = true;
        }
        console.log(this.currentRoomId);
        if (this.currentRoomId !== data.workOrderId) {
          chatRoom.newMessage = true;
          this.chatRooms.find(c => c.id === data.workOrderId).newMessage = true;
        }
      } else {
        this.getRooms();
      }
    });
    this.hubConnection.on('getRooms', () => {
      this.getRooms();
    });
    this.hubConnection.on('onError', (data: string) => {
      console.log(data);
    });
  }

}
