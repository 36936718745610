import { Component } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { ChatService } from '../../services/chat.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent{

    public menuItems: any[]
    isFolded : boolean;
    isSideNavDark : boolean;
    isExpand : boolean;

    constructor( private themeService: ThemeConstantService, private chatService: ChatService, public translateService:TranslateService) {}

    ngOnInit(): void {
        this.translateService.get('routes').subscribe(routes =>{
            this.menuItems = routes
        })
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
        this.chatService.createConnection();
    }

    closeMobileMenu(): void {
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }
}
