<div class="header">
  <div class="logo logo-dark">
    <a style="display: flex; align-items: center; justify-content: space-evenly" href="">
      <img src="assets/moyotask/moyotask-logo.png" class="moyotask-logo" />
    </a>
  </div>
  <div class="logo logo-white">
    <a href="">
      <img src="assets/images/logo/logo-white.png" alt="Logo" />
      <img class="logo-fold" src="assets/images/logo/logo-fold-white.png" alt="Logo" />
    </a>
  </div>
  <div class="nav-wrap">
    <ul class="nav-left">
      <!-- <li>
        <a (click)="searchToggle()">
          <i nz-icon
             nzType="search"
             theme="outline"></i>
        </a>
        <nz-drawer [nzWidth]="380"
                   nzTitle="Search"
                   [nzVisible]="searchVisible"
                   nzPlacement="left"
                   (nzOnClose)="searchToggle()">
          <app-search></app-search>
        </nz-drawer>
      </li> -->
      <!-- <li class="desktop-toggle">
        <a (click)="toggleFold()">
          <i nz-icon
             [nzType]="isFolded? 'menu-unfold':'menu-fold'"
             theme="outline"></i>
        </a>
      </li> -->
      <li class="mobile-toggle">
        <a (click)="toggleExpand()">
          <i nz-icon [nzType]="isExpand ? 'menu-fold' : 'menu-unfold'" theme="outline"></i>
        </a>
      </li>
    </ul>
    <ul class="nav-right">
      <!-- <li>
        <nz-select (ngModelChange)="changeLanguage($event)" [(ngModel)]="language">
          <nz-option nzValue="en" nzLabel="English"></nz-option>
          <nz-option nzValue="tr" nzLabel="Turkish"></nz-option>

        </nz-select>
      </li> -->
      <li>
        <a nz-dropdown (click)="openBellNotifications()" nzTrigger="click" [nzDropdownMenu]="popNotification"
          [nzPlacement]="'bottomRight'">
          <nz-badge [nzCount]="notifications">
            <i nz-icon nzType="bell" theme="outline"></i>
          </nz-badge>
        </a>
        <nz-dropdown-menu #popNotification="nzDropdownMenu">
          <div nz-menu class="pop-notification">
            <div class="
                p-v-15 p-h-25
                border-bottom
                d-flex
                justify-content-between
                align-items-center
              ">
              <p class="text-dark font-weight-semibold m-b-0">
                <i nz-icon nzType="bell" theme="outline"></i>
                <span class="m-l-10">{{"notifications" | translate}}</span>
              </p>
              <a style="color:#42509E;" nzSize="small" class="p-v-5" [routerLink]="['/notifications']">
                <small>{{"seeAll" | translate}}</small>
              </a>
            </div>
            <nz-list class="ant-list-item-links" [nzDataSource]="notificationsList" [nzRenderItem]="item"
              [nzItemLayout]="'horizontal'">
              <ng-template #item let-item>
                <nz-list-item>
                  <div class="d-flex" style="padding: 10px;">
                    <div class="m-l-10 m-r-10">
                      <p class="m-b-0 text-dark">{{ language === "tr" ? item.message : item.messageEnglish }}</p>
                      <p class="m-b-0">
                        <small>{{ item.createdAt }}</small>
                      </p>
                    </div>
                  </div>
                </nz-list-item>
              </ng-template>
            </nz-list>
          </div>
        </nz-dropdown-menu>
      </li>

      <li>
        <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'"
          [nzPlacement]="'bottomRight'">
          <nz-avatar [nzIcon]="'user'"></nz-avatar>
        </span>

        <div style="margin-right:50px">
          <nz-dropdown-menu #profile="nzDropdownMenu">
            <ul *ngIf="loggedUser" nz-menu class="p-b-15 p-t-20" style="margin-right:45px;">
              <li class="p-h-20 p-b-15 m-b-10 border-bottom" nz-menu-item (click)="toProfile()">
                <div class="d-flex m-r-50">
                  <nz-avatar nzSize="large" [nzIcon]="'user'"></nz-avatar>
                  <div class="m-l-10">
                    <p class="m-b-0 text-dark font-weight-semibold">
                      {{ loggedUser.firstName }}
                    </p>
                    <p class="m-b-0 opacity-07">{{ loggedUser.lastName }}</p>
                  </div>
                </div>
              </li>

              <!-- <li nz-menu-item>
              <a class="p-v-5 d-flex align-items-center justify-content-between">
                <div>
                  <i class="opacity-04 font-size-16"
                     nz-icon
                     nzType="lock"
                     theme="outline"></i>
                  <span class="m-l-10">Ayarlar</span>
                </div>
                <i class="font-size-10"
                   nz-icon
                   nzType="right"
                   theme="outline"></i>
              </a>
            </li> -->
              <li (click)="logout()" nz-menu-item>
                <a class="p-v-5 d-flex align-items-center justify-content-between">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                    <span class="m-l-10">{{"logout" | translate}}</span>
                  </div>
                  <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                </a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </li>
      <!-- <li>
        <a (click)="quickViewToggle()">
          <i nz-icon
             nzType="appstore"
             theme="outline"></i>
        </a>
        <nz-drawer [nzWidth]="280"
                   nzTitle="Kısayollar"
                   [nzClosable]="false"
                   [nzVisible]="quickViewVisible"
                   nzPlacement="right"
                   (nzOnClose)="quickViewToggle()">
          <app-quick-view></app-quick-view>
        </nz-drawer>
      </li> -->
    </ul>
  </div>
</div>